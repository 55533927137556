import React, { useState,useEffect } from "react";
import "./App.css";
import Sidebar from "./Components/sidebar"; // Import the Sidebar component
import { Button } from "@material-ui/core";
import { HomePage } from "./Components/homepage";
import { AboutUs } from "./Components/about";
import { PrivacyPolicy } from "./Components/privacy";
import {Navbar} from './Components/navbar';
import {TermsOfUse} from './Components/terms';
import {FAQPage} from './Components/faq';
import {Footer} from './Components/footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const iframeUrls = {
  cardio: "https://llm-bot-git-cardio-revca.vercel.app/",
  ortho: "https://llm-bot-git-ortho-revca.vercel.app/",
  onco: "https://llm-bot-git-onco-revca.vercel.app/",
  neuro: "https://llm-bot-git-test4-revca.vercel.app/",
  daisy: "https://daisy.muskan.ai/",
  gynae: "https://llm-bot-git-gynae-revca.vercel.app",
  genmed: "https://llm-bot-git-genmed-revca.vercel.app",
  paediatrics: "https://llm-bot-git-paed-revca.vercel.app",
};

function App() {
  
  const [currentIframeUrl, setCurrentIframeUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const handleIconClick = (key) => {
    setCurrentIframeUrl(iframeUrls[key]);
    setLoading(true);
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };


  // for footer
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Assuming mobile width is 768px
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="App">
      <Navbar onIconClick={handleIconClick} />
      <Sidebar
        onIconClick={handleIconClick}
        toggleSidebar={toggleSidebar}
        expanded={sidebarExpanded}
      />
      <main className={sidebarExpanded ? "expanded" : ""}>
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
            <p>Loading...</p>
          </div>
        )}
        {currentIframeUrl ? <React.Fragment>
          <iframe
            src={currentIframeUrl}
            title="External Content"
            width="100%"
            height="100%"
            onLoad={handleIframeLoad}
          ></iframe>  <br/>
          {isMobile && <Footer />}
        </React.Fragment> : (
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfUse />} />
              <Route path="/faqs" element={<FAQPage />} />
            </Routes>
          <div>
          {isMobile && <Footer />}
          </div>
          </Router>
        )}
      </main> 
    </div>
  );
}

export default App;
