import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShieldAlt,
  faFileContract,
  faQuestion,
  faComment,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import './footer.css';

export const Footer = () => {
  return (
    <footer style={{backgroundColor:'rgb(51,51,51)',padding:'10px', marginRight:'10px'}}>
      <div className="mobile-footer">
        <a href="/privacy">
          <FontAwesomeIcon icon={faShieldAlt} /> Privacy Policy
        </a><br/>
        <a href="/terms">
          <FontAwesomeIcon icon={faFileContract} /> Terms & Conditions
        </a><br/>
        <a href="/faqs">
          <FontAwesomeIcon icon={faQuestion} /> FAQs
        </a><br/>
        <a href="https://forms.gle/2HtCCdQ1kKzcB7a16">
          <FontAwesomeIcon icon={faComment} /> Feedback
        </a><br/>
        <a href="/about">
          <FontAwesomeIcon icon={faInfoCircle} /> About Us
        </a><br/>
      </div>
    </footer>
  );
};

