import React from "react";
export const AboutUs=()=>{
   return (
       <React.Fragment>
           <div className="about-us">
      <h1>About Us</h1>
      <p>
        Allow me to introduce myself, I'm MedAssist, an AI Engine trained to contribute towards the betterment of individual health, community well-being, and global health standards in an ethically responsible manner, adhering to high standards of integrity. By offering support and assistance in understanding health-related issues, I aim to play a role in creating healthier individuals and societies.
      </p>

      <p>
        As an AI Medical assistant, I engage only in health-related discussions. I interact with users in a manner similar to a medical consultation. Please follow the instructions below:
      </p>
      
      <ul>
        <li>For better assistance, please choose one of the medical specialties that closely matches your health problem.</li>
        <li>MedAssist will ask relevant questions and provide options and guidance based on its training.</li>
        <li>While its primary language is English, it is also proficient in Hindi, Spanish, French, and Arabic, catering to a diverse user base.</li>
      </ul>

      <p>
        We are always adding more features and functionalities, and we are working with various experts across the globe towards offering healthcare advice to everyone across the globe.
      </p>

      <p>
        However, it is important to note that I do not offer any medical advice or formulate treatment plans. I primarily serve as a support tool to aid and assist, and for informational purposes only. I strongly recommend and encourage users to always seek the guidance of qualified healthcare professionals for interpretations of MedAssist's insights. Before using MedAssist, users should familiarize themselves with the Terms and Privacy Policy of the service.
      </p>

      <p>
        I’d love to hear from you. You can share your thoughts at <a href="mailto:hello@anai.io">hello@anai.io</a>. Our team of experts and advisors will go over your suggestions and ideas, and will be more than happy to engage with you.
      </p>

      <p>
        Sincerely,<br/>
        Team MedAssist & Team DAISY<br/>
        <a href="mailto:Daisy@muskan.ai">Daisy@muskan.ai</a><br/>
        <a href="mailto:MedAssist@anai.io">MedAssist@anai.io</a>
      </p>
    </div>
       </React.Fragment>
   )
};