import React, { useState} from "react";
import "./Sidebar.css"; 
function Sidebar({ onIconClick }) {
  const [activeIcon, setActiveIcon] = useState(null);


  const handleIconClick = (iconKey) => {
   
    setActiveIcon(iconKey);
    onIconClick(iconKey);
  };


  return (
    <React.Fragment>
      <div className={`sidebar`}>
        <div className="sidebar-icons">
          {/* Apply the 'active' class to the icon that is active */}
          <div
            className={`icon ${activeIcon === "daisy" ? "active" : ""}`}
            onClick={() => handleIconClick("daisy")}
          >
            <div style={{textAlign:'center'}}>
              <span className="icon-text">Dental</span>
              <span className="icon-text">(For Teeth)</span>
            </div>
          </div>
          <div
            className={`icon ${activeIcon === "cardio" ? "active" : ""}`}
            onClick={() => handleIconClick("cardio")}
          >
           <div style={{textAlign:'center'}}>
            <span className="icon-text">Cardiology</span>
              <span className="icon-text">(Heart Health)</span>
            </div>
            
          </div>
          <div
            className={`icon ${activeIcon === "ortho" ? "active" : ""}`}
            onClick={() => handleIconClick("ortho")}
          >
            <div style={{textAlign:'center'}}>
            <span className="icon-text">Orthopaedics</span>
              <span className="icon-text">(Bones & Joints)</span>
            </div>
            
          </div>
          <div
            className={`icon ${activeIcon === "onco" ? "active" : ""}`}
            onClick={() => handleIconClick("onco")}
          >
            <div style={{textAlign:'center'}}>
            <span className="icon-text">Oncology</span>
              <span className="icon-text">(Cancer Care)</span>
            </div>
           
          </div>
          <div
            className={`icon ${activeIcon === "neuro" ? "active" : ""}`}
            onClick={() => handleIconClick("neuro")}
          >
            <div style={{textAlign:'center'}}>
            <span className="icon-text">Neurology</span>
              <span className="icon-text">(Brains & Nerves)</span>
            </div> 
           
          </div>
          <div
            className={`icon ${activeIcon === "gynae" ? "active" : ""}`}
            onClick={() => handleIconClick("gynae")}
          >
             <div style={{textAlign:'center'}}>
             <span className="icon-text">Gynecology</span>
              <span className="icon-text">(Women's Health)</span>
            </div> 
           
          </div>
          <div
            className={`icon ${activeIcon === "genmed" ? "active" : ""}`}
            onClick={() => handleIconClick("genmed")}
          >
            <div style={{textAlign:'center'}}>
            <span className="icon-text">General Med</span>
              <span className="icon-text">(General Health)</span>
            </div> 
           
          </div>
          <div
            className={`icon ${activeIcon === "paediatrics" ? "active" : ""}`}
            onClick={() => handleIconClick("paediatrics")}
          >
            <div style={{textAlign:'center'}}>
            <span className="icon-text">Pediatrics</span>
              <span className="icon-text">(Child Health)</span>
            </div> 
           
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;
