import React from "react";

export const FAQPage = () => {
  return (
    <React.Fragment>
      <div className="faq-page">
        <section className="patient-faqs">
          <h2>FAQs for Patients</h2>

          {/* Question: Purpose of MedAssist and DAISY */}
          <div className="faq">
            <h3>What is the purpose of MedAssist and DAISY?</h3>
            <p>
              MedAssist is designed to assist patients in various medical
              fields, including Dental (DAISY), Oncology, Neurology,
              Orthopaedics, Gynecology, Pediatrics, General medicine, and
              Cardiology, by providing accurate and tailored health information.
            </p>
          </div>

          {/* Question: Printout or Report to Healthcare Professional */}
          <div className="faq">
            <h3>
              Can I take a printout or report of MedAssist and/or DAISY to my
              healthcare professional?
            </h3>
            <p>
              While MedAssist is in beta mode and not a substitute for
              professional consultation, users can share the information they
              gather with their healthcare providers for further discussion.
            </p>
          </div>

          {/* Question: Prescribing Medicine or Treatment Plan */}
          <div className="faq">
            <h3>
              Will MedAssist and/or DAISY prescribe me medicine or give me a
              treatment plan?
            </h3>
            <p>
              No, MedAssist and DAISY do not prescribe medications or offer
              treatment plans. They serve as informative tools to assist users
              in understanding potential health issues.
            </p>
          </div>

          {/* Question: Difference from Search Engines */}
          <div className="faq">
            <h3>
              How is MedAssist and/or DAISY different than a search engine like
              Google or Bing?
            </h3>
            <p>
              MedAssist is tailored for healthcare-related queries and utilizes
              AI to provide accurate and relevant information, focusing
              specifically on medical fields for more precise responses.
            </p>
          </div>

          {/* Question: Unsupported Specialties */}
          <div className="faq">
            <h3>
              What if my healthcare specialty, e.g., Nephrology or Pulmonary, is
              not supported by MedAssist?
            </h3>
            <p>
              MedAssist aims to expand its coverage in the future. Users are
              encouraged to provide feedback for future enhancements if a
              specific medical specialty is not currently supported.
            </p>
          </div>

          {/* Question: Payment for Use */}
          <div className="faq">
            <h3>Do I have to pay to use it?</h3>
            <p>
              No, MedAssist is currently in beta mode, and usage is free. Users
              can access the service without any charge.
            </p>
          </div>

          {/* Question: Multiple Questions Issue */}
          <div className="faq">
            <h3>
              I am trying to use MedAssist and rather than asking one question
              at a time, it is asking me multiple questions.
            </h3>
            <p>
              MedAssist is trained to ask one question but at times it may
              respond by showing all the 10 questions. If this happens, please
              respond to questions by prefixing 1.0 in front of it and watch the
              video for more guidance.
            </p>
          </div>

          {/* Question: Discussing Multiple Problems */}
          <div className="faq">
            <h3>Can I ask or discuss multiple problems in a single session?</h3>
            <p>
              You cannot, as it's important to ensure that context doesn’t get
              mixed up. For multiple queries or conditions, start a new session
              for each medical condition. Refreshing the browser and starting a
              new session is the best option.
            </p>
          </div>
        </section>

        <section className="professional-faqs">
          <h2>FAQs for Healthcare Professionals</h2>

          {/* Question: Contribution to Research and Innovation */}
          <div className="faq">
            <h3>
              As a healthcare professional, how can I contribute to MedAssist?
            </h3>
            <p>
              Healthcare professionals interested in contributing can reach out
              through the feedback form on the MedAssist site or by emailing
              medassist@anai.io.
            </p>
          </div>

          {/* Question: Usage by Healthcare Professionals */}
          <div className="faq">
            <h3>As a healthcare professional, how can I use it?</h3>
            <p>
              Healthcare professionals can use MedAssist as a supplementary tool
              for information, but should rely on their clinical expertise for
              comprehensive patient care.
            </p>
          </div>

          {/* Question: Discussing Business Ideas */}
          <div className="faq">
            <h3>
              I have a business idea, and I want to discuss it further; whom
              shall I contact?
            </h3>
            <p>
              For business ideas or collaborations, contact the team via the
              information available on the MedAssist site.
            </p>
          </div>

          {/* Question: Commercial Version Support */}
          <div className="faq">
            <h3>
              Do you support a commercial version, especially if I want to use
              it for my multi-specialty hospital?
            </h3>
            <p>
              Plans for commercial versions and support for multi-specialty
              hospitals are under consideration. Interested parties can express
              their requirements through the contact information on the
              MedAssist site.
            </p>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};
