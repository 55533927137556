import React from 'react';

export const TermsOfUse=()=> {
  return (
    <div className="terms-container">
      <div className="section">
        <h1>Terms of Use</h1>
        <p>These terms of use are entered into by and between You and Anai.io MedAssist, DASIY ("anai.io,",”muskan.ai” "we," or "us"). Anai.io & Muskan.ai are owned by Revca, LLC. Anai.io and Muskan.ai are a service and brand owned and operated by Revca, LLC a U.S. entity registered in Maryland, U.S. The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use") govern your access to and use of anai.io or medassist.anai.io including any content, functionality, and services offered on or through anai.io or medassist.anai.io (the "Website") and the Muskan.ai mobile app (the “App” and collectively with the Website, the “Services”), whether as a guest or a registered user.</p>

        <p>These Terms incorporate by reference our Privacy Policy, as well as any other written policies and documentation that we may provide from time to time. You agree to use our Services in compliance with these Terms.</p>
      </div>

      <div className="section">
        <h2>Our Services</h2>
        <p>Revca and its platform, products and services including (ANAI.io MedAssist and Muskan.ai DAISY), especially the ones that are based on Conversational AI will fundamentally reshape the way that we interact and use computers in our everyday lives. As you interact with our Services, we will learn more about how to make this emerging technology ever more helpful, collaborative, and easy to communicate with.</p>

        <p>Given the early state of this technology and this initiative, we also acknowledge that there are areas that will need to continually improve to ensure that our Services can reach their full potential. As part of that, you recognize that:</p>

        <ul>
          <li>We will use the content of your conversations to improve our Services.</li>
          <li>We will use the content of your conversations as well as other metadata to train our models, improve our Services, and create new Services. Our uses of this data are detailed in our Privacy Policy.</li>
        </ul>

        <p>You must use our Services in accordance with our Acceptable Use policy. Your access to our Services is conditioned on your agreement to use the Services in accordance with these Terms generally and our Acceptable Use policy (as detailed below in “Acceptable Use”).</p>

        <p>The information you receive from our Services may not be accurate. You recognize that any information you receive from our Services may not be accurate or otherwise trustworthy. Do not rely on this information without fact-checking on your own first or consulting with a professional. Getting factuality right remains an active area of research for the entire field of conversational AI, and it remains a major priority for the development of Revca services going forward. Please review the “No Warranties” section below.</p>

        <p>Your use of our Services in any manner means that you agree to the Terms. Unfortunately, if you do not agree with these Terms, you may not access or use our Services.</p>

        <p>Please read the Terms of Use carefully before you start to use the Services. We also facilitate access to online clinical telehealth services and secure messaging from independent licensed healthcare professionals (the “Providers”) to patients through the Services. By using the Services or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at [https://www.anai.io/privacy], incorporated herein by reference. If you are accepting these Terms of Use for another person (“Family Member”) as such Family Member’s lawful parent, guardian, conservator, or custodian, you agree to the terms, conditions, and notices contained or referenced herein on behalf of such Family Member. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Services. These Services are offered and available to users who are 18 years of age or older, and reside in the United States or any of its territories or possessions, and in other countries. By using the Services, you represent and warrant that you are of legal age to form a binding contract with anai.io and meet all of the foregoing eligibility requirements. </p>
      </div>
      <div className="section">
        <h2>Important Notices</h2>
        <p>Please note that these Terms of Use contain provisions that govern the resolution of claims between us and you, including an arbitration agreement, class action waiver, and jury trial waiver that affect your rights. In arbitration, there is no judge or jury and there is less discovery and appellate review than in court.</p>

        <p>If you do not meet all of these requirements, you must not access or use the Services.</p>

        <p>Anai.io MedAssist and/or Muskan.ai DAISY does not provide medical advice or care nor does anai.io or Muskan.ai contract with any provider of care. Neither anai.io, Muskan.ai nor any third party who promotes the Services or provides you with a link to the Services, shall be liable for any professional advice you obtained from a Provider via the Services, nor for any information obtained on the Services. You acknowledge that your reliance on any or information delivered by the Services is solely at your own risk and you assume full responsibility for all associated risks.</p>

        <p>The content of the Services, including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals, is for informational purposes only and does not constitute professional medical advice, diagnosis, treatment, or recommendations of any kind by anai.io or muskan.ai. You should always seek the advice of qualified health care professionals with any questions or concerns you may have regarding your individual needs and any medical conditions. While anai.io MedAssist and muskan.ai DAISY facilitates your selection of, and communications with, Providers, anai.io MedAssist or muskan.ai DAISY does not provide medical services, and the provider-patient relationship is between you and your Medical Group Provider. The services and information provided by anai.io MedAssist and/or Muskan.ai DAISY, are intended to be for general information purposes only.</p>

        <p>You agree that these Terms of Use govern your access to, and use of the Services offered by anai.io. Please refer to our Privacy Policy <a href="https://www.anai.io/privacy">https://www.anai.io/privacy</a> to learn how we use, share, and protect your protected health information.</p>
      </div>

      <div className="section">
        <h2>Acceptable Use</h2>
        <p>You agree that you will use our Services in accordance with the following rules, as well as any other written policies we may provide from time to time:</p>

        <ul>
          <li><strong>Illegal Use:</strong> You must use our Services in compliance with the law. If your use of our Services is limited by the laws applicable to you, you are not authorized to use our Services for that purpose.</li>

          <li><strong>Harmful Uses:</strong> You may not use or attempt to use our Services to generate harmful content. Such content includes material that may lead to serious harm to yourself or others, content relating to unethical behavior, or content that may spread misinformation. This policy also bars attempts to use the Services to create content to be fraudulently represented as human-generated.</li>

          <li><strong>Abusive Content:</strong> You may not use or attempt to use our Services to generate hateful or discriminatory content, sexually explicit content, descriptions of graphic violence, or other types of shocking material.</li>

          <li><strong>Infringing Rights:</strong> You may not use or attempt to use our Services to infringe or violate the rights of others, including violations of the privacy of others. This includes queries to surface the personal information of individuals, including phone numbers, addresses, and other similar sensitive data.</li>

          <li><strong>Security:</strong> You may not attempt to engage with our Services in an effort to disable, disrupt, or otherwise subvert the security of our Services. This includes attempts to bypass or disable any content moderation and safety measures implemented within our Services.</li>

          <li><strong>Reverse Engineering:</strong> You may not use or attempt to use our Services to reverse engineer, decompile, or otherwise attempt to obtain the underlying models, algorithms, or source code of the Services. You may not engage in this or any other activities with regards to our Services to build products that may be competitive with Revca.</li>

          <li><strong>Scraping:</strong> You may not scrape or attempt to “crawl” or “spider” any page, data, or portion of our Services, either via manual or automated means.</li>
        </ul>
      </div>

      <div className="section">
        <h2>Changes to the Terms of Use</h2>
        <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Services thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice before the date the change is posted on the Services. Your continued use of the Services following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
      </div>

      <div className="section">
        <h2>Accessing the Services and Account Security</h2>
        <p>We reserve the right to withdraw or amend the Services, and any service or material we provide on the Services, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Services is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Services, or the Services in their entirety, to users, including registered users. You are responsible for both:</p>
        <ul>
          <li>Making all arrangements necessary for you to have access to the Services.</li>
          <li>Ensuring that all persons who access the Services through your internet connection are aware of these Terms of Use and comply with them.</li>
        </ul>
      </div>

      <div className="section">
        <h2>Intellectual Property Rights</h2>
        <p>The Services and their entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by anai.io, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. These Terms of Use permit you to use the Services for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Services, except as follows:</p>
        
        <ul>
          <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
          <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
          <li>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</li>
          <li>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
          <li>If we provide social media features with certain content, you may take such actions as are enabled by such features.</li>
        </ul>
        
        <p>You must not:</p>
        <ul>
          <li>Modify copies of any materials from the Services.</li>
          <li>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>
          <li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Services.</li>
        </ul>

        <p><strong>Feedback:</strong> We welcome any and all feedback, ideas for improvement, product proposals, and other suggestions. However, if you provide this to us, you recognize that we may use this without restriction and without any further notice or compensation to you.</p>
      </div>

      <div className="section">
        <h2>Copyright Notices</h2>
        <p>If you believe that your copyright has been infringed by something on our Services, you can send us a DMCA compliant copyright notice by sending an email to info@anai.io or info@muskan.ai On receipt of a compliant notice, we shall work to promptly remove or disable material identified as infringing. Please include the following information in your notice:</p>

        <ul>
          <li>A description of the work or works you claim have been infringed;</li>
          <li>A description of the content on our Services you claim to be infringing;</li>
          <li>Your contact information, including mailing address, telephone number, and email address;</li>
          <li>Your statement confirming a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;</li>
          <li>Your statement confirming that the information you’ve provided is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
          <li>Your signature.</li>
        </ul>

        <p>You must not access or use for any commercial purposes any part of the Services or any services or materials available through the Services. If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Services in breach of the Terms of Use, your right to use the Services will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Services or any content on the Services is transferred to you, and all rights not expressly granted are reserved by anai.io. Any use of the Services not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</p>
      </div>

      <div className="section">
        <h2>Trademarks</h2>
        <p>The anai.io name, the anai.io logo, and all related names, logos, product and service names, designs, and slogans are trademarks of anai.io or its affiliates or licensors. You must not use such marks without the prior written permission of anai.io. All other names, logos, product and service names, designs, and slogans on the Services are the trademarks of their respective owners.</p>
      </div>

      <div className="section">
        <h2>Prohibited Uses</h2>
        <p>You may use the Services only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Services:</p>

        <ul>
          <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
          <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
          <li>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards set out in these Terms of Use.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
          <li>To impersonate or attempt to impersonate anai.io, a anai.io employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</li>
          <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm anai.io or users of the Services, or expose them to liability.</li>
        </ul>

        <p>Additionally, you agree not to:</p>

        <ul>
          <li>Use the Services in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Services, including their ability to engage in real time activities through the Services.</li>
          <li>Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services.</li>
          <li>Use any manual process to monitor or copy any of the material on the Services, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
          <li>Use any device, software, or routine that interferes with the proper working of the Services.</li>
          <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
          <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services is stored, or any server, computer, or database connected to the Services.</li>
          <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.</li>
          <li>Otherwise attempt to interfere with the proper working of the Services.</li>
        </ul>
      </div>

      <div className="section">
        <h2>User Contributions</h2>
        <p>The Services may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactives  (collectively, "Interactive Services") that allow users to post, submit, publish, display, or transmit to users or other persons (hereinafter, "post") content or materials (collectively,"User Contributions") on or through the Services. All User Contributions must comply with the Content Standards set out in these Terms of Use. Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Services, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose.</p>
        <p>You represent and warrant that:</p>
        <ul>
          <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.</li>
          <li>All of your User Contributions do and will comply with these Terms of Use.</li>
        </ul>
        <p>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not anai.io, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by you or any other user of the Services.</p>
      </div>

      <div className="section">
        <h2>Monitoring and Enforcement; Termination</h2>
        <p>We have the right to:</p>
        <ul>
          <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
          <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Services or the public, or could create liability for anai.io.</li>
          <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
          <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services.</li>
          <li>Terminate or suspend your access to all or part of the Services for any or no reason, including without limitation, any violation of these Terms of Use.</li>
        </ul>
        <p>Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services.</p>
        <p>YOU WAIVE AND HOLD HARMLESS anai.io AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES. However, we do not undertake to review all material before it is posted on the Services, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>
      </div>

      <div className="section">
        <h2>Limitation of Liability</h2>
        <p>UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR OTHERWISE) SHALL REVCA BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, OR (B) FOR ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES IN EXCESS ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THESE TERMS.</p>
      </div>

      <div className="section">
        <h2>No Warranties</h2>
        <p>YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. REVCA EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</p>
        <p>REVCA MAKES NO WARRANTY THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS; (B) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.</p>
      </div>

      <div className="section">
        <h2>Content Standards</h2>
        <p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
        <ul>
          <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
          <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
          {/* Additional bullet points */}
        </ul>
      </div>
      <h2>Copyright Infringement</h2>
      <p>
        Reporting Claims of Copyright Infringement: We take claims of copyright infringement seriously. 
        We will respond to notices of alleged copyright infringement that comply with applicable law...
      </p>
      <p>
        If you believe any materials accessible on or from the Services infringe your copyright, 
        you may request removal of those materials (or access to them) from the Services by submitting 
        written notification to our copyright agent designated below...
      </p>
      <ul>
        <li>Your physical or electronic signature.</li>
        <li>Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Services, a representative list of such works.</li>
        <li>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</li>
        <li>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
        <li>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</li>
        <li>A statement that the information in the written notice is accurate.</li>
        <li>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
      </ul>

      <p>
        Our designated copyright agent to receive DMCA Notices is: <a href="mailto:legal@anai.io">legal@anai.io</a>.
      </p>
      <p>
        If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective. 
        Please be aware that if you knowingly materially misrepresent that material or activity on the Services is infringing your copyright, 
        you may be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.
      </p>
      <p>
        Counter Notification Procedures: If you believe that material you posted on the Services was removed or access to it was disabled by mistake or misidentification, 
        you may file a counter notification with us (a "Counter Notice") by submitting written notification to our copyright agent designated above...
      </p>
      <p>
        Repeat Infringers: It is our policy in appropriate circumstances to disable and terminate the accounts of users who are repeat infringers.
      </p>
      <p>
        Reliance on Information Posted: The information presented on or through the Services is made available solely for general information purposes...
      </p>

      <p>
        Changes to the Services: We may update the content on the Services from time to time, but its content is not necessarily complete or up-to-date...
      </p>
      <p>
        Information About You and Your Visits to the Services: All information we collect on the Services is subject to our Privacy Policy...
      </p>
      <p>
        Linking to the Services and Social Media Features: You may link to our homepage, provided you do so in a way that is fair and legal...
      </p>
      <ul>
        <li>Establish a link from any website that is not owned by you.</li>
        <li>Cause the Services or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</li>
        <li>Link to any part of the Services other than the homepage.</li>
        <li>Otherwise take any action with respect to the materials on this Services that is inconsistent with any other provision of these Terms of Use.</li>
      </ul>
      <p>
        You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice...
      </p>

      
      <p>
        Links from the Services: If the Services contain links to other sites and resources provided by third parties...
      </p>
      <p>
        Geographic Restrictions: The owner of the Services is based in the United States. We provide the Services for use only by persons located in the United States...
      </p>
      <p>
        Disclaimer of Warranties: You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Services will be free of viruses or other destructive code...
      </p>
      <p>
        Your use of the Services, their content, and any services or items obtained through the Services is at your own risk...
      </p>
      <p>
        Neither anai.io nor any person associated with anai.io makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the Services...
      </p>
      <p>
        To the fullest extent provided by law, anai.io hereby disclaims all warranties of any kind...
      </p>

      <p>
        Limitation on Liability: TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL anai.io, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND...
      </p>
      <p>
        TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, THE MAXIMUM LIABILITY OF anai.io TO YOU WITH RESPECT TO YOUR ACCESS OR USE OF THIS SITE OR ANY CONTENT OR MATERIALS THEREIN SHALL NOT EXCEED THE GREATER OF (I) THE FEES YOU PAID TO anai.io IN THE THREE (3) MONTHS PRECEDING THE DATE ON WHICH YOUR CLAIM AROSE, OR (II) $100 (ONE HUNDRED DOLLARS)...
      </p>
      <p>
        Indemnification: You agree to defend, indemnify, and hold harmless anai.io, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Services...
      </p>
      <p>
        Dispute Resolution: (This section is missing from your input. If you provide the content for this section, I can include it in the React component.)
      </p>

      <h2>Legal Disputes</h2>
        <p>
          Any dispute, claim, or controversy (that is not resolved informally as set forth below) between you and anai.io
          and their agents, employees, officers, directors, principals, successors, assigns, subsidiaries, affiliates
          (collectively for purposes of this section, “anai.io Parties”) arising from or relating in any way to:
        </p>
        <ol>
          <li>These Terms of Use and their interpretation or the breach, termination, or validity thereof, and the relationships which result from these Terms of Use;</li>
          <li>Your use of any Services owned or operated by anai.io Parties; or</li>
          <li>Any products or services sold or distributed by anai.io Parties or through any website owned or operated by anai.io Parties (collectively, “Covered Disputes”)</li>
        </ol>
        <p>
          Will be resolved by binding arbitration, rather than in court. Covered Disputes shall be interpreted broadly.
        </p>

        <h3>Mandatory Informal Dispute Resolution</h3>
        <p>
          anai.io Parties seek to resolve disputes informally where possible. Before formally pursuing a Covered Dispute in arbitration, you agree to first send a detailed notice (“Notice”) to anai.io Parties by email at legal@anai.io. Your Notice must contain all of the following information:
        </p>
        <ol>
          <li>Your full name;</li>
          <li>Your address, telephone number, and email address; and</li>
          <li>A detailed description of your dispute, the nature and basis of your claim(s), and the nature and basis of the relief you are seeking. You must personally sign this Notice.</li>
        </ol>
        <p>
          You and anai.io Parties agree to negotiate in good faith about the dispute in an effort to swiftly resolve it to your satisfaction without the need for a formal proceeding. Should anai.io Parties request a telephone conference with you in an effort to resolve your dispute as part of this informal process, you agree to personally participate (with your counsel if you are represented). This process should result in resolution of the dispute, but if for some reason it is not resolved within 60 days after receipt of a fully completed Notice and the parties have not agreed to extend this time period, you may initiate an arbitration.
        </p>

        <section>
    <h1>Arbitration Fees</h1>
    <p>
      Payment of all filing, administration, and arbitrator fees will be governed by the NAM Rules (or the AAA Rules should AAA be the designated administrator as set forth above). Upon a showing of financial hardship, anai.io Parties will consider your request to promptly reimburse your portion of the arbitration fees provided for in the NAM (or AAA) Rules.
    </p>
  </section>

  <section>
    <h1>Arbitration Authority</h1>
    <p>
      THE ARBITRATOR HAS THE SOLE AUTHORITY TO AND SHALL ADDRESS ALL CLAIMS OR ARGUMENTS BY BOTH PARTIES CONCERNING THE FORMATION, LEGALITY, AND ENFORCEABILITY OF THIS ARBITRATION CLAUSE, THE SCOPE OF THIS CLAUSE, AND THE ARBITRABILITY OF ANY CLAIM OR ISSUE ARISING BETWEEN US.
    </p>
  </section>

  <section>
    <h1>Arbitration Details</h1>
    <p>
      There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages) only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. The arbitrator may not award relief for or against anyone who is not a party to the proceeding. The arbitrator is bound by and must follow the terms of these Terms of Use as a court would. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to address the essential findings and conclusions of law on which the award is based. The arbitration award shall be binding only between you and anai.io Parties and shall have no preclusive effect in any other arbitration or proceeding involving a different party, provided that the arbitrator may consider rulings in other arbitrations involving different individuals. The arbitrator may award fees and costs as provided by the NAM Rules (or the AAA Rules if AAA is the designated administrator as set forth above) or to the extent such fees and costs could be awarded in court or if the arbitrator determines that a claim, proceeding, or defense was frivolous or brought for harassment, for an improper purpose, or in bad faith. The arbitrator shall apply the provisions of Federal Rule of Civil Procedure 68 after entry of the award.
    </p>
  </section>

  <section>
    <h1>Sole Exceptions to Arbitration</h1>
    <p>
      Notwithstanding the foregoing, in lieu of arbitration: (1) either you or anai.io Parties may elect to have an individual claim heard in small claims court consistent with any applicable jurisdictional and monetary limits that may apply, provided that it is brought and maintained as an individual claim and is not appealed or removed to any court of general jurisdiction; and (2) you agree that you or anai.io Parties may bring suit in court to enjoin infringement or other misuse of intellectual property rights.
    </p>
  </section>

  <section>
    <h1>Jury Trial and Class Action Waiver</h1>
    <p>
      EXCEPT AS THE TERMS OF USE OTHERWISE PROVIDE AND TO THE FULLEST EXTENT PERMITTED BY LAW, YOU, anai.io PARTIES ACKNOWLEDGE AND AGREE THAT YOU ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY AND TO THE LITIGATION OF DISPUTES IN STATE OR FEDERAL COURTS OF GENERAL JURISDICTION. THE PARTIES FURTHER AGREE THAT ANY ARBITRATION SHALL BE CONDUCTED IN THEIR INDIVIDUAL CAPACITIES ONLY AND NOT AS A CLASS ACTION/CLASS ARBITRATION OR OTHER REPRESENTATIVE ACTION, AND THE PARTIES EXPRESSLY WAIVE THEIR RIGHT TO FILE A CLASS ACTION OR SEEK RELIEF ON A CLASS BASIS. YOU AND anai.io PARTIES MAY NOT BE PLAINTIFFS OR CLASS MEMBERS IN ANY PURPORTED CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL, OR REPRESENTATIVE PROCEEDING, OR OTHERWISE MAKE OR PROCEED WITH ANY CLAIM ON A COLLECTIVE OR CONSOLIDATED BASIS, AND MAY EACH BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY. AS REFERENCED ABOVE, THE ARBITRATOR MAY AWARD DECLARATORY OR INJUNCTIVE RELIEF ONLY ON AN INDIVIDUAL BASIS AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THE INDIVIDUAL CLAIM. IF A COURT DETERMINES THAT THIS CLASS ACTION WAIVER IS NOT ENFORCEABLE AS TO A PARTICULAR CLAIM OR REQUEST FOR RELIEF AND ALL APPEALS FROM THAT DECISION HAVE BEEN EXHAUSTED (OR THE DECISION IS OTHERWISE FINAL), THEN THE PARTIES AGREE THAT THAT PARTICULAR CLAIM OR REQUEST FOR RELIEF SHALL PROCEED IN COURT BUT SHALL BE STAYED PENDING ARBITRATION OF THE REMAINING CLAIMS.
    </p>
  </section>

  <h1>Waiver and Severability</h1>
    <p>
        No waiver by anai.io of any term or condition set out in these Terms of Use will be deemed a further or continuing
        waiver of such term or condition or a waiver of any other term or condition, and any failure of anai.io to assert
        a right or provision under these Terms of Use will not constitute a waiver of such right or provision. If any provision
        of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or
        unenforceable for any reason, such provision will be eliminated or limited to the minimum extent such that the remaining
        provisions of the Terms of Use will continue in full force and effect.
    </p>

    <h1>Your Comments and Concerns</h1>
    <p>
        The Services are operated by anai.io, Inc. All notices of copyright infringement claims should be sent to the copyright
        agent designated above in the manner and by the means set out therein. All other feedback, comments, requests for
        technical support, and other communications relating to the Services should be directed to: <a href="mailto:info@anai.io">info@anai.io</a>.
    </p>

    <h1>Miscellaneous Terms</h1>

    <h2>Violations</h2>
    <p>Revca reserves the sole discretion to determine whether or not a user is in violation of any of the provisions of these Terms.</p>

    <h2>Indemnification</h2>
    <p>
        To the fullest extent allowed by applicable law, you agree to indemnify and hold Revca, its affiliates, officers, agents,
        employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential),
        losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims relating
        to (a) your use of our Services (including any actions taken by a third party using your account), and (b) your violation
        of these Terms.
    </p>

    <h2>Assignment</h2>
    <p>
        You may not assign or delegate any rights or obligations under these Terms, including in connection with a change of control.
        Any purported assignment and delegation shall be null and void. We may assign these Terms in connection with a merger,
        acquisition or sale of all or substantially all of our assets, or to any affiliate or as part of a corporate reorganization.
    </p>

    <h2>Choice of Law</h2>
    <p>
        These Terms are governed by and will be construed under applicable federal law and the laws of the State of Maryland, without
        regard to the conflicts of laws provisions thereof.
    </p>

    <h2>Arbitration</h2>
    <p>
        Any dispute arising from or relating to the subject matter of these Terms shall be finally settled by arbitration in Howard
        County, Maryland, in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation
        Services, Inc. (“JAMS”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual
        property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance
        with the Streamlined Arbitration Rules and Procedures of JAMS. Judgment upon the award so rendered may be entered in a court
        having jurisdiction, or application may be made to such court for judicial acceptance of any award and an order of enforcement,
        as the case may be. Notwithstanding the foregoing, you and Revca shall have the right to institute an action in a court
        of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator.
    </p>

    <h2>Jurisdiction and Venue</h2>
    <p>
        For all purposes of these Terms, you consent to exclusive jurisdiction and venue in the state or federal courts located in,
        respectively, Howard County, Maryland or in the state of Maryland. The prevailing party in any action or proceeding arising
        out of these Terms will be entitled to an award of costs and attorneys’ fees.
    </p>

    <h2>Class Action Waiver</h2>
    <p>
        To the fullest extent permitted by law, you and Revca agree that all claims against the other can only be brought in an
        individual capacity, and not as a plaintiff or class member in any purported class, consolidated, or other representative
        proceeding. We agree that arbitrators may not conduct any class, consolidated, or representative proceeding, and are limited
        to providing relief warranted by an individual party’s claim.
    </p>

    <h2>No Joint Venture or Beneficiaries</h2>
    <p>
        You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Revca, and you do not
        have any authority of any kind to bind us in any respect whatsoever. There are also no third-party beneficiaries intended
        under these Terms.
    </p>

    <h2>Waiver</h2>
    <p>
        The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights
        hereunder.
    </p>

    <h2>Notices</h2>
    <p>
        Notices under these Terms shall be provided to you under the information provided to Revca when you registered your account.
        Notices to Revca may be provided by sending a message to <a href="mailto:info@anai.io">info@anai.io</a> or <a href="mailto:info@muskan.ai">info@muskan.ai</a>
        or by mail at Revca LLC, 3304 Governor Howard Dr, Ellicott City, MD, 21043. Notice shall be considered effective as of
        the date of receipt.
    </p>

    <h2>Severability</h2>
    <p>
        If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated,
        to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable.
    </p>

    <h2>Entire Agreement</h2>
    <p>
        You agree that these Terms are the complete and exclusive statement of the mutual understanding between you and us, and
        that it supersedes and cancels all previous written and oral agreements, communications and other understandings relating
        to the subject matter of these Terms.
    </p>
    </div>
  );
}
