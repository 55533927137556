import React from "react";
export const HomePage = () => {
  return (
    <React.Fragment>
      <div className="homepage">
        <section className="introduction">
          <h2>Introduction</h2>
          <p>
            MedAssist by ANAI is an AI initiative aimed at providing safe and
            affordable medical information to anyone, anywhere. It's a versatile,
            multi-lingual platform that operates like a medical expert, capable
            of addressing health specific questions. Currently its in Beta
            phase, MedAssist is continuously evolving. For any questions,
            feedback, or suggestions, please contact us at{" "}
            <a href="mailto:medassist@anai.io">medassist@anai.io</a> or{" "}
            <a href="mailto:daisy@muskan.ai">daisy@muskan.ai</a>.
          </p>
          <p>
            It's important to note that MedAssist does not offer medical advice
            or treatment plans. Always consult with a qualified healthcare
            professional for such needs. Make sure to read the{" "}
            <a href="https://medassist.anai.io/terms">Terms & Conditions</a>{" "}
            along with{" "}
            <a href="https://medassist.anai.io/terms">Privacy Policy</a>
            before using MedAssist.
          </p>
        </section>

        <section className="instructions">
          <h2>Instructions on how to use MedAssist</h2>
          <p>
            MedAssist, as an AI Medical Expert, interacts similarly to a
            patient-doctor consultation, focusing exclusively on health-related
            discussions. Please adhere to the following instructions:
          </p>
          <ul>
            <li>
              For better assistance, please choose one of the medical
              specialties that closely matches your health problem.
            </li>
            <li>
              MedAssist will pose pertinent questions and offer choices,
              guidance, and suggestions.
            </li>
            <li>
              MedAssist is Multi-Lingual. While its primary and preffered
              language is <b>English</b>, it can also understand
            </li>
            <ul>
              <li>
                <b>Hindi (हिंदी)</b>
              </li>
              <li>
                <b>Arabic (العربية)</b>
              </li>
              <li>
                <b>Spanish (Español)</b>
              </li>
              <li>
                <b>French (Français)</b>
              </li>
              <li>
                <b>German (Deutsch)</b>
              </li>
              <li>
                And several world languages, including Portuguese, Chinese,
                Japanese, among others.
              </li>
            </ul>
          </ul>
        </section>

        <section className="instructions">
          <p>
            ANAI MedAssist's primary goal is to enhance individual health,
            community wellness, and global health standards. It strives to
            support and assist in comprehending health-related issues, aiming to
            foster healthier individuals and societies.
          </p>
        </section>
        <section className="instructions">
          <h2>Disclaimer</h2>
          <p>
            It is important to note that ANAI's MedAssist does not offer medical
            advice or formulate treatment plan. Instead, it serves as a support
            tool to aid in understanding health-related information. Users are
            encouraged to always seek the guidance of qualified healthcare
            professionals for interpretations of MedAssist's insights. Before
            using MedAssist, users should familiarize themselves with the{" "}
            <a href="https://medassist.anai.io/terms">Terms & Conditions</a>{" "}
            along with{" "}
            <a href="https://medassist.anai.io/terms">Privacy Policy</a> of the
            service.
          </p>
        </section>
      </div>
    </React.Fragment>
  );
};
