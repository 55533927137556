import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
    <h2>Privacy Policy</h2>
    <p>
      This Privacy Policy outlines how Revca LLC (ANAI.IO & MUSKAN.AI) (“Revca”, “ANAI”, “Muskan”, “we”, “us”) handles your personal information through our websites, our mobile applications, our interactions with you through other modes of communication (such as SMS or instant messaging), and any other sites or services that link to this Privacy Policy (collectively, the “Services”).
    </p>
    
    <p>
      At Revca LLC, ANAI.IO, and Muskan.AI (“we,” “us,” “our”), we understand the importance of protecting your personal information. This Privacy Policy (“Policy”) outlines how we at Revca LLC, ANAI.IO, and Muskan.AI collect, use, share, and protect the personal information that you provide to us when you access or use our website(s), ANAI.IO & Muskan.ai (the “Sites”), and our services (“Services”). By using our Site and Services, you agree to the terms of this Policy. If you do not agree with the terms of this Policy, please do not use our Site or Services.
    </p>

    <p>
      Conversational artificial intelligence is at the forefront of our services. We believe that this emerging technology will fundamentally change the way that we engage with and think about computers and digital devices in our everyday lives. Ultimately, our goal is to use AI to build safe, smart, kind, and engaging conversational partners.
    </p>

    <p>
      Our Privacy Policy reflects our belief that achieving this goal and making the technology truly useful for all will require a deep understanding of how our users talk and collaborate with AIs. As users interact with our AIs, we learn a lot about how to make AI better and more useful to you.
    </p>

    <p>
      Users deserve transparency regarding how that process works, particularly when it comes to their data. This Policy is one part of that transparency. It documents what data we collect, how we keep it secure, and describes how we use that data to improve our Services for everyone. Here are the top level points:
    </p>

    <ul>
        <li>
          When you use our Revca and/or its services, we collect data including your name, phone number, and IP address. We use this data to run our services, make improvements to the platform, keep you safe, and comply with all applicable laws.
        </li>
        <li>
          Keeping your conversations with our Revca and/or its Services private and safe is our top priority, and we will never sell or share your data for advertising or marketing purposes. We have strict internal controls over the use of and access to user data.
        </li>
        <li>
          You agree to follow our Acceptable Use policy. When you talk to Revca and/or its services, you should not try to get it to talk about harmful, abusive, or illegal topics. You also should not attempt to evade our security measures or learn about the models, algorithms, prompts, or source code of Pi. If our automated systems detect this activity, you may be suspended.
        </li>
        <li>
          You are not permitted to use Revca and/or its services if you are under the age of 18.
        </li>
        <li>
          Revca and/or its services may make up facts, events, or advice. You should never rely on anything it tells you without double-checking the facts yourself or, if appropriate, talking to a professional.
        </li>
      </ul>

      <h2>Information We Collect</h2>
      <p>We at ANAI.io and Muskan.ai may collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Information used to identify you, such as name, email address, mailing address, phone number, date of birth, and other provided information.
        </li>
        <li>
          <strong>Health Information:</strong> Includes medical history, health status, and healthcare-related data you provide.
        </li>
        <li>
          <strong>Usage Information:</strong> Data about interactions with our Site and Services, like IP address, browser type, visited pages, and visit duration.
        </li>
        <li>
          <strong>Cookies and Other Tracking Technologies:</strong> Usage of cookies, web beacons, and similar technologies to gather information on Site and Services usage.
        </li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We at ANAI.io and Muskan.ai may use your personal information for the following purposes:</p>
      <ul>
        <li>To provide Services, manage accounts, and send communications related to our Services.</li>
        <li>To enhance our Site and Services, develop features, conduct research, and analyze usage data.</li>
        <li>To personalize user experiences, display relevant content, advertisements, and recommendations.</li>
        <li>To communicate about promotions, updates, and Service-related news.</li>
        <li>To protect our Site and Services from security incidents, fraud, and enforce agreements.</li>
        <li>
          For research and development, including analyzing and improving Services and business, using anonymized data derived from personal information.
        </li>
      </ul>

      <h2>How We Share Your Information</h2>
      <p>
        We at Revca, Anai, and/or Muskan do not share your personal information without explicit permission, unless required by law or in response to legal requests.
        In cases of mergers, acquisitions, or asset sales, personal information may be transferred to the new owner.
      </p>

      <h2>Security</h2>
      <p>
        We take the security of your personal information seriously, implementing appropriate measures to protect it. However, absolute security cannot be guaranteed.
      </p>
      <h2>Your Choices and Rights</h2>
      <p>You have the following choices and rights regarding your personal information:</p>
      <ul>
        <li><strong>Access:</strong> Request access to the personal information we hold about you.</li>
        <li><strong>Correction:</strong> Request correction of any inaccurate or incomplete personal information.</li>
        <li><strong>Deletion:</strong> Request deletion of your personal information, subject to certain exceptions.</li>
        <li><strong>Object or Restrict Processing:</strong> Object to or restrict the processing of your personal information under certain circumstances.</li>
        <li><strong>Withdraw Consent:</strong> Withdraw your consent for the processing of your personal information at any time.</li>
        <li><strong>Data Portability:</strong> Request a copy of your personal information in a structured, commonly used, and machine-readable format and transmit that data to another controller without hindrance.</li>
      </ul>

      <p>To exercise any of these rights, please contact us at privacy@anai.io or privacy@muskan.ai.</p>

      <h2>Third-Party Websites and Services</h2>
      <p>
        Our Site and Services may contain links to third-party websites or integrate with third-party services. This Privacy Policy does not apply to the information collected by these third parties. We encourage you to review the privacy policies of these third-party websites and services to understand their information practices.
      </p>

      <h2>Children’s Privacy</h2>
      <p>
        Our Site and Services are not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete that information as soon as possible.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. When we make changes, we will update the “Last Updated” date at the beginning of this Policy. We encourage you to review this Policy periodically to stay informed about our information practices.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our information practices, please contact us at privacy@anai.io or privacy@muskan.ai.
      </p>

      <p>
        By using our Site and Services, you acknowledge that you have read and understand this Privacy Policy, and agree to the collection, use, and sharing of your personal information as described herein. If you do not agree with the terms of this Privacy Policy, please do not use our Site or Services.
      </p>
  </div>
  );
};

