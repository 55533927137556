import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faShieldAlt,
  faFileContract,
  faHome,
  faComment,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";

export const Navbar = ({ onIconClick }) => {
  const [showLinks, setShowLinks] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeIcon, setActiveIcon] = useState(null);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth > 768) {
      setShowLinks(true);
    } else {
      setShowLinks(false);
    }
  }, [windowWidth]);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  // for the sidebar shit ;)
   
  const handleIconClick = (iconKey) => {
   
    setActiveIcon(iconKey);
    onIconClick(iconKey);
    setShowLinks(false);
  };

  return (
    <div className="navbar">
      <div className="navbar-logo" style={{textAlign:'center'}}>
        <span style={{padding:'0',fontSize:'24px'}}><a style={{textDecoration:'none'}} href='/'>MedAssist by ANAI</a></span><br/>
        <span style={{fontSize:'12px',}}><i>From Queries to Clarity - Your Health Ally, MedAssist.</i></span>
      </div>
      {windowWidth > 768 ? ( 
        <div className={`navbar-links ${showLinks ? "show" : ""}`}>
          <a href="/privacy">
            <FontAwesomeIcon icon={faShieldAlt} /> Privacy Policy
          </a>
          <a href="/terms">
            <FontAwesomeIcon icon={faFileContract} /> Terms & Conditions
          </a>
          <a href="/faqs">
            <FontAwesomeIcon icon={faQuestion} /> FAQs
          </a>
          <a href="https://forms.gle/2HtCCdQ1kKzcB7a16">
            <FontAwesomeIcon icon={faComment} /> Feedback
          </a>
          <a href="/about">
            <FontAwesomeIcon icon={faInfoCircle} /> About Us
          </a>
        </div>
      ) : ( 
      <div className={`navbar-links ${showLinks ? "show" : ""}`}>
        
       <div className='navbar-doc-link'>
       <a className={`${activeIcon === "daisy" ? "active-nav" : ""}`} onClick={() => handleIconClick("daisy")}>Dental  (For Teeth)</a> 
        <a className={`${activeIcon === "cardio" ? "active-nav" : ""}`} onClick={() => handleIconClick("cardio")}>Cardiology  (Heart Health)</a> 
        <a className={`${activeIcon === "ortho" ? "active-nav" : ""}`} onClick={() => handleIconClick("ortho")}>Orthopaedics  (Bones & Joints)</a> 
        <a className={`${activeIcon === "onco" ? "active-nav" : ""}`} onClick={() => handleIconClick("onco")}>Oncology  (Cancer Care)</a> 
        <a className={`${activeIcon === "neuro" ? "active-nav" : ""}`} onClick={() => handleIconClick("neuro")}>Neurology  (Brains & Nerves)</a> 
        <a className={`${activeIcon === "gynae" ? "active-nav" : ""}`} onClick={() => handleIconClick("gynae")}>Gynecology  (Women's Health)</a> 
        <a className={`${activeIcon === "genmed" ? "active-nav" : ""}`} onClick={() => handleIconClick("genmed")}>General Med  (General Health)</a> 
        <a className={`${activeIcon === "paediatrics" ? "active-nav" : ""}`} onClick={() => handleIconClick("paediatrics")}>Pediatrics  (Child Health)</a> 
       </div>
    </div>
      )}
      {windowWidth <= 768 && (
        <div className="navbar-toggle" onClick={toggleLinks}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </div>
  );
};
















// import React, { useState, useEffect } from 'react';
// import './Navbar.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faInfoCircle,
//   faShieldAlt,
//   faFileContract,
//   faHome,
//   faComment,
//   faQuestion
// } from "@fortawesome/free-solid-svg-icons";


//  export const Navbar = () => {
//   const [showLinks, setShowLinks] = useState(true);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   const handleResize = () => {
//     setWindowWidth(window.innerWidth);
//   };

//   useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   useEffect(() => {
//     if (windowWidth > 768) {
//       setShowLinks(true);
//     } else {
//       setShowLinks(false);
//     }
//   }, [windowWidth]);

//   const toggleLinks = () => {
//     setShowLinks(!showLinks);
//   };

//   return (
//     <div className="navbar">
//       <div className="navbar-logo" style={{textAlign:'center'}}>
//         <span style={{padding:'0',fontSize:'24px'}}><a style={{textDecoration:'none'}} href='/'>MedAssist by ANAI</a></span><br/>
//         <span style={{fontSize:'12px',}}><i>From Queries to Clarity - Your Health Ally, MedAssist.</i></span>
//       </div>
//       <div className={`navbar-links ${showLinks ? "show" : ""}`}>
//         <a href="/privacy">
//           <FontAwesomeIcon icon={faShieldAlt} /> Privacy Policy
//         </a>
//         <a href="/terms">
//           <FontAwesomeIcon icon={faFileContract} /> Terms & Conditions
//         </a>
//         <a href="/faqs">
//           <FontAwesomeIcon icon={faQuestion} /> FAQs
//         </a>
//         <a href="https://forms.gle/2HtCCdQ1kKzcB7a16">
//           <FontAwesomeIcon icon={faComment} /> Feedback
//         </a>
//         <a href="/about">
//           <FontAwesomeIcon icon={faInfoCircle} /> About Us
//         </a>
//       </div>
//       {windowWidth <= 768 && (
//         <div className="navbar-toggle" onClick={toggleLinks}>
//           <span></span>
//           <span></span>
//           <span></span>
//         </div>
//       )}
//     </div>
//   );
// };
